/**
 * @name 无需服务端获取的微应用
 */
// 无需登录的应用
const noAuthApps = [
   
]

// 需要登陆身份但是和模块菜单授权无关的子应用
const nextAuthApps = []

export {noAuthApps, nextAuthApps};