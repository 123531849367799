// 配置js文件路径

// 开发
export default {
    authAccount: "hiwooiot",
    BASEURL: 'https://zdyw.jszhidao.com',

    WEBSOCKET_SERVER_URL: "wss://zdyw.jszhidao.com/springboot/app-basic",
    environment_protection: "",
    energy: "",
    factory: "",
    other: "",

    maintenance: "/springboot/app-maintenance/",
    userMessage: {
        getUserMessage: "/message/getUserMessage",
        readUserMessage: "/message/readUserMessage",
    },

    basic: "/springboot/app-basic/",

    userSet: "user/register",
    editUser: "user/editUser",
    login: "user/loginCommoner",
    loginOut: "user/loginout",
    repass: "user/editPassword",
    resetPass: "user/resetPassword",

    getMenu: "jurisdiction/getMenuByUser",
    getNotificationMessage: "msg/getMessage",
    getRoleJurisdiction: "role/getRoleJurisdiction",
    newestMessage: "upgradeNotification/newestMessage",
    readNotificationMessage: "msg/readNotificationMessage",
    readAlarmMessage: "msg/readAlarmMessage",

    getRandom32: "/user/getRandom32",
    getUInfo: "/user/getUInfo",

    getProjects: "projectCenter/getList",
}
