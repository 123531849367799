export default {
    namespaced: true,
    state: {
        system: '',
        token: '',
        userInfo: null,
        roleInfo: null,
        projectId:null
    },
    mutations: {
        // 设置分页大小
        SET_SYSTEM(state, data) {
            state.system = data;
        },
        // 设置token
        SET_TOKEN(state, data) {
            state.token = data;
        },
        SET_USERINFO(state, data) {
            state.userInfo = data;
        },
        SET_ROLEINFO(state, data) {
            state.roleInfo = data;
        },
        SET_PROJECTID(state, data) {
            state.projectId = data;
        }
    },
    actions: {
        // 设置分页大小
        setSystem({commit}, data) {
            commit('SET_SYSTEM', data)
        },
        // 设置token
        setToken({commit}, data) {
            commit('SET_TOKEN', data)
        },
        setUserInfo({commit}, data) {
            commit('SET_USERINFO', data)
        },
        setRoleInfo({commit}, data) {
            commit('SET_ROLEINFO', data)
        },
        setProjectId({commit}, data) {
            commit('SET_PROJECTID', data)
        }
    }
}