// 正向-树形结构转平铺
// 从外到内依次递归，有 children 则继续递归
export const treeToArr = function treeToArr(data, pid="HW6000000713685116", res=[]) {
    data.forEach(item => {
      res.push({  id: item.id, name: item.name});
      if(item.children && item.children.length !== 0) {
        treeToArr(item.children, item.id, res);
      }
    });
    return res;
  };
  